
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  ref,
  useAttrs,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  ApiBase,
  ApiShipments,
  ApiLogisticsServiceProvider,
  ApiDocumentsInvoice,
} from "@/core/api";
import UseStockOut from "@/views/transport-management/shipments/UseStockOut.vue";
import addPreStockOutList from "@/views/transport-management/shipments/AddStockOut.vue";
import mixin from "@/mixins";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import { useI18n } from "vue-i18n";
import {
  commonBackToList,
  commonChangeDefaultDate,
  commonExportFile,
  commonPreviewFile,
  setModuleBCN,
} from "@/core/directive/function/common";
import {
  getOrderOfficialPrices,
  getOrderProductIdData,
} from "@/core/directive/function/order";
import { NumberOrString } from "@/core/directive/type/common";
import {
  ProductItem,
  ProductItemOfficialPrices,
} from "@/core/directive/interface/order";
import { TaggingItem } from "@/core/directive/interface/common";
import JwtService from "@/core/services/JwtService";
import { ElUpload } from "element-plus";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import { getShipmentStatusMap } from "@/core/directive/function/shipment";
import { ShipmentStatus } from "@/core/directive/type/shipment";
import { MenuComponent } from "@/assets/ts/components";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";

export default defineComponent({
  name: "transport-management-shipment-information",
  components: {
    UseStockOut,
    addPreStockOutList,
    ProductInfo,
    SkuInfo,
    PermissionCommon,
  },
  setup(props, context) {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();
    const attrs = useAttrs(); // 接收父路由参数

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const upload_file_id = ref<InstanceType<typeof ElUpload>>();
    const shipmentItemId = ref(0);
    const parentId = computed(() => {
      return parseInt(route.params.id.toString());
    });
    const id = route.params.id;
    const uploadUrl = ApiShipments.uploadUrl();
    let childWin: any;
    let childWinTwo: any;

    const formData = ref({
      id: route.params.id,
      can_manually_delivered: 0,
      order_type_oc: 0,
      order_type_ds: 0,
      shipment_info: {
        created_at: null,
        id: route.params.id,
        shipment_id: "",
        status: "",
        shipment_type: "",
        fulfillment_stage: "",
        invoice_status: 0,
        service_provider: "" as string | number,
        pick_up_by: "",
        tracking_no: "",
        departure_delivery_from: "",
        destination: "",
        destination_warehouse_id: "",
        trade_term: "",
        delivery_from_location: {
          name: "",
          street: "",
          zip_code: "",
          city: "",
          country_iso_2: "",
          type: 0,
        },
        destination_location: {
          name: "",
          street: "",
          zip_code: "",
          city: "",
          country_iso_2: "",
        },
        file_id: 0,
        file_name: "",
        // EU Shipment
        actual_time_of_departure: null,
        estimated_time_of_departure: null,
        actual_time_of_arrival: null,
        estimated_time_of_arrival: null,
      },
      shipment_items: [] as ProductItem[],
      __show: {
        status: "",
        shipment_type: "",
      },
      // Overseas Shipment
      shipment_invoice_id: 0,
      carrier_information: {
        created_at: null,
        destination_port: "",
        flight_departure_date: null,
        flight_landing_date: null,
        flight_number: "",
        custom_declaration_by: "",
        export_custom_declaration: "",
        import_custom_declaration: "",
        id: "",
        shipment_id: "",
      },
      packing_specification: {
        actual_gross_weight: "",
        chargeable_weight: "",
        created_at: null,
        dimension: "",
        id: 1,
        packing_container: 0,
        quantity: 0,
        shipment_id: "",
        total_amount: "",
        total_gross_weight: "",
        total_volume: "",
        total_volume_weight: "",
        shipment_fee_amount: "",
        shipment_fee_currency: "",

        additional_fee_amount: "",
        additional_fee_currency: "",
      },
      st_billing: {},
      // CN Shipment
    });

    const options = ref({
      defaultFormData: {
        tracking_no: "",
        actual_time_of_departure: "",
        destination_port: "",
        trade_term: "",
      },
      fulfillment_stage: [] as TaggingItem[],
      shipment_type: [] as TaggingItem[],
      service_provider: [] as TaggingItem[],
      delivery_from_location: "",
      destination_location: "",
      log_type: [] as TaggingItem[],
      shipment_service_profile: [] as TaggingItem[],
      shipment_items: [] as ProductItem[],
      file_id: [] as any[],
      loadingProducts: true,
      printCheckListDisabled: false,
      // EU Shipment
      // Overseas Shipment
      custom_declaration_by: [] as TaggingItem[],
      tms_shipment_trade_term: [] as TaggingItem[],
      packing_container: [] as TaggingItem[],
      shipments_destination_port: [] as TaggingItem[],
      createProformaInvoiceDisabled: false,
      createDocumentsDisabled: false,
      createAgentDocumentsDisabled: false,
      previewProformaInvoiceDisabled: false,
      previewDoubleSaleCommercialInvoiceDisabled: false,
      previewDeliveryNoteDisabled: false,
      printPackingListDisabled: false,
      downloadPackingListDisabled: false,
      stockOutListDisabled: false,
      // CN Shipment
    });

    const rules = ref({
      // status: [
      //   {
      //     required: true,
      //     message: "Status is required",
      //     trigger: "change",
      //   },
      // ],
      "shipment_info.fulfillment_stage": [
        {
          required: true,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (value === "" || value === 0 || value === null) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      "shipment_info.service_provider": [
        {
          required: true,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (
                value === "" &&
                typeSameRule &&
                formData.value.shipment_info.status ==
                  String(ShipmentStatus.Created)
              ) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      "shipment_info.trade_term": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "carrier_information.destination_port": [
        {
          required: true,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (
                value === "" &&
                formData.value.shipment_info.delivery_from_location.type !== 1
              ) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    });

    watch(
      () => formData.value.shipment_info.tracking_no,
      (newValue, oldValue) => {
        formData.value.shipment_info.tracking_no = newValue.replace(
          /[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\-\u4E00-\u9FA5]/g,
          ""
        );
      }
    );

    const removeFlag = ref(false);

    const uploadDisabled = ref({
      file_id: false,
      avm: false,
      proforma_invoice: false,
      packing_list: false,
    });

    const itemTable = ref([]);

    const typeSameRule = computed(() => {
      const shipment_type = formData.value.shipment_info.shipment_type;
      if (!isDoubleSale.value) {
        return shipment_type == "2" || shipment_type == "3";
      } else {
        return shipment_type == "2";
      }
    });

    const getShowInfo = async (isNew = false) => {
      if (isNew) loading.value = true;
      const { data } = await ApiShipments.showShipment({ id: route.params.id });
      if (isNew) loading.value = false;
      if (data.code == 0) {
        formData.value = data.data;
        if (data.data.shipment_info.file_id != 0) {
          options.value.file_id = [
            {
              name: data.data.shipment_info.file_name,
              url: "",
            },
          ];
        }
        // if (formData.value.shipment_info.service_provider == "-1") {
        //   formData.value.shipment_info.tracking_no = "";
        // } else {
        //   formData.value.shipment_info.pick_up_by = "";
        // }
        options.value.defaultFormData.tracking_no =
          data.data.shipment_info.tracking_no;
        options.value.defaultFormData.actual_time_of_departure =
          data.data.shipment_info.actual_time_of_departure;
        if (data.data.carrier_information) {
          options.value.defaultFormData.destination_port =
            data.data.carrier_information.destination_port;
        }
        if (data.data.shipment_info.shipment_type == "2") {
          options.value.defaultFormData.trade_term =
            data.data.shipment_info.trade_term;
        }
        if (isNew === true) {
          // formData.value.shipment_items = data.data.shipment_items;
          // formData.value.shipment_invoice_id = data.data.shipment_invoice_id;
        } else {
          if (
            formData.value.shipment_info.service_provider !== "" &&
            formData.value.shipment_info.service_provider !== -1
          ) {
            getShipmentServiceProfileList();
          }
        }
        let ids: any = [];
        data.data.shipment_items.forEach((item) => {
          ids.push(item.sku_identifier);
        });
        getProductsData({
          sku_id: ids,
          merchant_id: 0,
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const getProductsData = async (ids) => {
      options.value.loadingProducts = true;
      const { data } = await ApiBase.getProductsData(ids);
      options.value.loadingProducts = false;
      if (data.code == 0) {
        options.value.shipment_items = data.data;
      }
    };

    const getProductIdData = computed(() => {
      return (id: NumberOrString) => {
        return getOrderProductIdData(id, options.value.shipment_items);
      };
    });

    const getOfficialPrices = computed(() => {
      return (officialPrices: ProductItemOfficialPrices[] | undefined) => {
        return getOrderOfficialPrices(officialPrices);
      };
    });

    const serviceProviderChange = () => {
      if (formData.value.shipment_info.service_provider == "-1") {
        formData.value.shipment_info.tracking_no = "";
      } else {
        formData.value.shipment_info.pick_up_by = "";
      }
    };

    const isDisabledTrackingNo = computed(() => {
      let flag = true;
      const tracking_no = options.value.defaultFormData.tracking_no,
        shipment_status = formData.value.shipment_info.status;
      if (typeSameRule.value) {
        if (shipment_status === String(ShipmentStatus.Created)) {
          flag = false;
        } else if (
          shipment_status === String(ShipmentStatus.InTransit) &&
          tracking_no === ""
        ) {
          flag = false;
        }
      }
      return flag;
    });

    const handleUseStockOut = () => {
      shipmentItemId.value = 0;
    };

    const handleEditUseStockOut = (row) => {
      shipmentItemId.value = row.id;
    };

    const getLocation = computed(() => {
      return (location, needName?: boolean) => {
        let result = "";
        if (needName) {
          result += location.name;
        }
        if (location.street) {
          if (needName) {
            if (location.name) {
              result += ", ";
            }
          }
          result += location.street;
        }
        if (location.zip_code) {
          if (location.name || location.street) {
            result += ", ";
          }
          result += location.zip_code;
        }
        if (location.city) {
          if (location.name || location.street || location.zip_code) {
            result += ", ";
          }
          result += location.city;
        }
        if (location.country_iso_2) {
          if (
            location.name ||
            location.street ||
            location.zip_code ||
            location.city
          ) {
            result += ", ";
          }
          result += location.country_iso_2;
        }
        return result;
      };
    });

    const getLogisticsServiceProviderData = async () => {
      const { data } =
        await ApiLogisticsServiceProvider.getLogisticsServiceProviderData({
          max_item: "all",
        });
      if (data.code == 0) {
        let shipmentProviderOptionsData = data.data;
        let pickUp = {
          label: t("shipments.pickUpBy"),
          value: -1,
        };
        shipmentProviderOptionsData.push(pickUp);
        options.value.service_provider = shipmentProviderOptionsData;
      }
    };

    const getShipmentServiceProfileList = async () => {
      const { data } = await ApiShipments.getShipmentServiceProfileList({
        id: formData.value.shipment_info.service_provider,
      });
      if (data.code == 0) {
        options.value.shipment_service_profile = data.data.item;
      }
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "shipment_type",
          "fulfillment_stage",
          "fulfillment_delivery_type",
          "shipments_packing_container",
          "shipments_destination_port",
          "tms_shipment_trade_term",
        ],
      });
      if (data.code == 0) {
        options.value.fulfillment_stage = data.data.fulfillment_stage.items;
        options.value.shipment_type = data.data.shipment_type.items;
        options.value.packing_container =
          data.data.shipments_packing_container.items;
        options.value.shipments_destination_port =
          data.data.shipments_destination_port.items;
        options.value.custom_declaration_by =
          data.data.fulfillment_delivery_type.items;
        options.value.tms_shipment_trade_term =
          data.data.tms_shipment_trade_term.items;
      }
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([
        getShowInfo(),
        getLogisticsServiceProviderData(),
        getTaggingData(),
      ])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleReceivedShipment = (id) => {
      Swal.fire({
        text: t("common.confirmTheReceiptOfGoods"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } = await ApiShipments.deliveredShipment({
            id: id,
          });
          loading.value = false;
          if (data.code == 0) {
            Swal.fire({
              text: "Received successfully!",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              getFromInfo();
              context.emit("getFormData");
            });
          }
        }
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          // if (submitButton.value) {
          //   submitButton.value.setAttribute("data-kt-indicator", "on");
          // }
          showItemChangeTip(() => {
            ApiShipments.updateShipment(formData.value)
              .then(({ data }) => {
                loading.value = false;
                // submitButton.value?.removeAttribute("data-kt-indicator");
                if (data.code == 0) {
                  showFormSubmitSuccessMsg(() => {
                    getFromInfo();
                    if (
                      options.value.defaultFormData.actual_time_of_departure !=
                      formData.value.shipment_info.actual_time_of_departure
                    ) {
                      context.emit("getFormData");
                    }
                  });
                } else {
                  showServerErrorMsg(data);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const showItemChangeTip = (fn) => {
      if (formData.value.shipment_info.shipment_type == "2") {
        // if (
        //   formData.value.shipment_info.trade_term !==
        //     options.value.defaultFormData.trade_term &&
        //   options.value.defaultFormData.trade_term !== ""
        // ) {
        //   Swal.fire({
        //     text: t("shipments.itemChangeTip"),
        //     icon: "warning",
        //     showCancelButton: true,
        //     buttonsStyling: false,
        //     confirmButtonText: t("common.yes"),
        //     cancelButtonText: t("common.no"),
        //     customClass: {
        //       confirmButton: "btn btn-primary",
        //       cancelButton: "btn btn-light",
        //     },
        //   }).then(async (result) => {
        //     if (result.isConfirmed) {
        //       fn();
        //     }
        //   });
        // } else {
        //   fn();
        // }
        fn();
      } else {
        fn();
      }
    };

    const invoiceButtonGroupVisibleController = (tag: string) => {
      // 控制单据相关的生成、打印按钮的显示隐藏
      const shipment_type = formData.value.shipment_info.shipment_type;
      const isOverseasShipment = shipment_type == "2"; // 是跨境物流
      const isDomesticShipment = shipment_type == "3"; // 是本地物流
      const hasShipmentInvoice = formData.value.shipment_invoice_id > 0; // 有发票
      const notDeliveryByMerchant =
        formData.value.shipment_info.delivery_from_location.type !== 1; // 非商户直发
      const invoiceStatus = formData.value.shipment_info.invoice_status; // 单据状态

      const CrossBorderFromGermanyToChina = () => {
        const from =
          formData.value.shipment_info.delivery_from_location.country_iso_2;
        const to =
          formData.value.shipment_info.destination_location.country_iso_2;
        const chinaIsoCodes = ["CN", "HK", "MO"];
        return from === "DE" && chinaIsoCodes.includes(to);
      };

      if (tag === "createProformaInvoice") {
        // SS 生成形式发票
        const SingleSaleVisibleCondition =
          !isDoubleSale.value &&
          (isDomesticShipment ||
            (isOverseasShipment && !CrossBorderFromGermanyToChina()) ||
            (isOverseasShipment && formData.value.order_type_oc === 1)) &&
          !hasShipmentInvoice &&
          notDeliveryByMerchant;
        return SingleSaleVisibleCondition;
      }
      if (tag === "createDocuments") {
        // DS 生成单据
        const DoubleSaleVisibleCondition =
          isDoubleSale.value && isOverseasShipment && invoiceStatus == 10;
        return DoubleSaleVisibleCondition;
      }
      if (tag === "createAgentDocuments") {
        // SS Agent 模式生成单据
        const SingleSaleVisibleCondition =
          !isDoubleSale.value &&
          isOverseasShipment &&
          CrossBorderFromGermanyToChina() &&
          (invoiceStatus == 10 || invoiceStatus == 11) &&
          notDeliveryByMerchant &&
          formData.value.order_type_oc != 1;
        return SingleSaleVisibleCondition;
      }

      if (tag === "previewProformaInvoice") {
        // SS 打印形式发票
        const SingleSaleVisibleCondition =
          !isDoubleSale.value &&
          (isDomesticShipment ||
            (isOverseasShipment && !CrossBorderFromGermanyToChina())) &&
          hasShipmentInvoice &&
          notDeliveryByMerchant;
        return SingleSaleVisibleCondition;
      }
      if (tag === "printCommercialInvoice") {
        // DS 打印商业发票
        const DoubleSaleVisibleCondition =
          isDoubleSale.value && isOverseasShipment && invoiceStatus == 12;
        return DoubleSaleVisibleCondition;
      }
      if (tag === "printDeliveryNote") {
        // DS 打印交货单
        const DoubleSaleVisibleCondition =
          isDoubleSale.value && isOverseasShipment && invoiceStatus == 12;
        // SS Agent 模式打印交货单
        const SingleSaleVisibleCondition =
          !isDoubleSale.value &&
          isOverseasShipment &&
          CrossBorderFromGermanyToChina() &&
          invoiceStatus == 12 &&
          notDeliveryByMerchant;
        return DoubleSaleVisibleCondition || SingleSaleVisibleCondition;
      }
      if (tag === "printPackingList") {
        // OS物流下载装箱单
        const condition =
          typeSameRule.value && hasShipmentInvoice && notDeliveryByMerchant;
        return condition;
      }
      if (tag === "downloadPackingList") {
        // EU和CN物流下载装箱单
        const condition = !typeSameRule.value;
        return condition;
      }
      if (tag === "printCheckList") {
        // 打印理货单
        const condition = shipment_type != "1";
        return condition;
      }
      if (tag === "printStockOutList") {
        // 导出出库列表
        const condition =
          typeSameRule.value && hasShipmentInvoice && notDeliveryByMerchant;
        return condition;
      }
      return false;
    };

    const createProformaInvoice = async () => {
      if (formData.value.shipment_info.shipment_type == "2") {
        if (options.value.defaultFormData.destination_port === "") {
          Swal.fire({
            text: t("shipments.createProformaInvoiceTip"),
            icon: "error",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            //
          });
          return false;
        }
      }

      options.value.createProformaInvoiceDisabled = true;
      const { data } = await ApiDocumentsInvoice.addInvoice({
        id: route.params.id,
      });
      options.value.createProformaInvoiceDisabled = false;
      if (data.code == 0) {
        Swal.fire({
          text: "successfully!",
          icon: "success",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          getShowInfo(true);
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const createAgentDocuments = async () => {
      if (formData.value.shipment_info.shipment_type == "2") {
        if (options.value.defaultFormData.destination_port === "") {
          Swal.fire({
            text: t("shipments.createDocumentsTip"),
            icon: "error",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            //
          });
          return false;
        }
      }

      options.value.createAgentDocumentsDisabled = true;
      const { data } = await ApiDocumentsInvoice.addDeliveryAgent({
        id: route.params.id,
      });
      options.value.createAgentDocumentsDisabled = false;
      if (data.code == 0) {
        Swal.fire({
          text: "successfully!",
          icon: "success",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          getShowInfo(true);
        });
      } else {
        showServerErrorMsg(data);
        await getShowInfo(true);
      }
    };

    const createDocuments = async () => {
      if (formData.value.shipment_info.shipment_type == "2") {
        if (options.value.defaultFormData.destination_port === "") {
          Swal.fire({
            text: t("shipments.createDocumentsTip"),
            icon: "error",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            //
          });
          return false;
        }
      }

      options.value.createDocumentsDisabled = true;
      const { data } = await ApiDocumentsInvoice.addDelivery({
        id: route.params.id,
      });
      options.value.createDocumentsDisabled = false;
      if (data.code == 0) {
        Swal.fire({
          text: "successfully!",
          icon: "success",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          getShowInfo(true);
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const previewProformaInvoiceProforma = async () => {
      try {
        const data = await ApiDocumentsInvoice.printProformaInvoice({
          id: formData.value.shipment_invoice_id,
          type: "proforma",
        });
        return data;
      } catch (error) {
        return "";
      }
    };

    const previewProformaInvoiceDelivery = async () => {
      try {
        const data: any = await ApiDocumentsInvoice.printProformaInvoice({
          id: formData.value.shipment_invoice_id,
          type: "delivery",
        });
        return data;
      } catch (error) {
        return "";
      }
    };

    const previewProformaInvoice = async () => {
      options.value.previewProformaInvoiceDisabled = true;
      Promise.all([
        previewProformaInvoiceDelivery(),
        previewProformaInvoiceProforma(),
      ])
        .then(([item1, item2]) => {
          console.log(item1, item2);
          options.value.previewProformaInvoiceDisabled = false;
          if (item1) {
            commonExportFile(item1);
          }
          if (item2) {
            commonExportFile(item2);
          }
        })
        .catch((error) => {
          options.value.previewProformaInvoiceDisabled = false;
        });
    };

    const getFirstStBillingId = (): number => {
      const stBilling = formData.value.st_billing;
      if (
        stBilling &&
        typeof stBilling === "object" &&
        !Array.isArray(stBilling)
      ) {
        const firstKey = Object.keys(stBilling)[0];
        if (firstKey) {
          return stBilling[firstKey].id as number;
        }
      }
      return 0;
    };

    const previewDoubleSaleCommercialInvoice = async () => {
      options.value.previewDoubleSaleCommercialInvoiceDisabled = true;
      ApiDocumentsInvoice.printCommercialInvoice({
        id: getFirstStBillingId(),
      })
        .then((data) => {
          options.value.previewDoubleSaleCommercialInvoiceDisabled = false;
          commonExportFile(data);
        })
        .catch((error) => {
          options.value.previewDoubleSaleCommercialInvoiceDisabled = false;
          console.log(error);
        });
    };

    const previewDeliveryNote = async () => {
      options.value.previewDeliveryNoteDisabled = true;
      ApiDocumentsInvoice.printDeliveryNote({
        id: formData.value.shipment_invoice_id,
        type: "delivery",
      })
        .then((data) => {
          options.value.previewDeliveryNoteDisabled = false;
          commonExportFile(data);
        })
        .catch((error) => {
          options.value.previewDeliveryNoteDisabled = false;
          console.log(error);
        });
    };

    const printPackingList = () => {
      options.value.printPackingListDisabled = true;
      ApiDocumentsInvoice.printPackingList({
        id: formData.value.shipment_invoice_id,
      })
        .then((data) => {
          options.value.printPackingListDisabled = false;
          commonExportFile(data);
        })
        .catch((error) => {
          options.value.printPackingListDisabled = false;
        });
    };

    const downloadPackingList = () => {
      options.value.downloadPackingListDisabled = true;
      ApiDocumentsInvoice.downloadPackingList({
        id: route.params.id,
      })
        .then((data) => {
          options.value.downloadPackingListDisabled = false;
          commonExportFile(data);
        })
        .catch((error) => {
          options.value.downloadPackingListDisabled = false;
        });
    };

    const printCheckList = () => {
      options.value.printCheckListDisabled = true;
      ApiShipments.printCheckList({
        id: route.params.id,
      })
        .then((data) => {
          options.value.printCheckListDisabled = false;
          commonExportFile(data);
        })
        .catch((error) => {
          options.value.printCheckListDisabled = false;
          console.log(error);
        });
    };

    const printStockOutList = () => {
      options.value.stockOutListDisabled = true;
      ApiDocumentsInvoice.printStockOutList({
        id: formData.value.shipment_invoice_id,
      })
        .then((data) => {
          options.value.stockOutListDisabled = false;
          commonExportFile(data);
        })
        .catch((error) => {
          options.value.stockOutListDisabled = false;
          console.log(error);
        });
    };

    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });

    const handleProgress = (e, file, fileList, attributes) => {
      uploadDisabled.value[attributes] = true;
    };

    const handleSuccess = (response, file, fileList, sign, data) => {
      uploadDisabled.value[sign] = false;
      if (response.code !== 0) {
        Swal.fire({
          text: t("common.uploadErrors"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }
      data[sign] = response.data.file_id;
    };

    const handleError = (err, file, fileList, attributes) => {
      if (err) {
        uploadDisabled.value[attributes] = false;
        Swal.fire({
          text: t("common.uploadErrors"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    };

    const handleExceed = (files, fileList, sign) => {
      const refModal = upload_file_id.value;
      options.value.file_id = fileList;
      // refModal!.clearFiles();
      const file = files[0];
      refModal!.handleRemove(file, file.raw);
      refModal!.handleStart(file);
      refModal!.submit();
    };

    const handleRemove = (file, fileList, sign, data, options, removeFlag) => {
      const refModal = upload_file_id.value;
      // 上传文件超出限制会在页面上删除文件，这里重新将之前的文件回显
      if (removeFlag && data[sign]) {
        refModal!.handleStart(options[sign][0]);
        return;
      }
      data[sign] = 0;
      formData.value.shipment_info.file_name = "";
    };

    const handleDeleteUpload = (sign, data) => {
      const refModal = upload_file_id.value;
      if (options.value.file_id.length > 0) refModal!.clearFiles();
      data[sign] = 0;
      formData.value.shipment_info.file_name = "";
    };

    const handlePreview = (sign, form) => {
      const name = sign;
      uploadDisabled.value[sign] = true;
      ApiBase.getFile({
        id: form[sign],
      })
        .then(({ data }) => {
          uploadDisabled.value[sign] = false;
          let fileURL;
          // var blob = new Blob([data], { type: "application/pdf" });
          if (window.webkitURL != undefined) {
            // webkit or chrome
            fileURL = window.webkitURL.createObjectURL(data);
          } else if (window.URL != undefined) {
            // mozilla(firefox)
            fileURL = window.URL.createObjectURL(data);
          }
          // let url = encodeURIComponent(fileURL);
          childWin = window.open(
            fileURL,
            name,
            "height=700, width=600, top=10, left=10, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
          );
          childWin.onload = function () {
            // childWin.print();
            if (window.webkitURL != undefined) {
              // webkit or chrome
              window.webkitURL.revokeObjectURL(fileURL);
            } else if (window.URL != undefined) {
              // mozilla(firefox)
              window.URL.revokeObjectURL(fileURL);
            }
          };
        })
        .catch((error) => {
          uploadDisabled.value[sign] = false;
          console.log(error);
        });
    };

    const handleBeforeUpload = (file) => {
      const limit = 1024 * 1024 * 5;
      if (file.size > limit) {
        Swal.fire({
          text: t("common.uploadSizeErrors"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          removeFlag.value = false;
        });
        removeFlag.value = true;
        return false;
      }
    };

    const setDisabledDate = (date) => {
      const currentDate = moment(new Date()).valueOf(),
        dates = moment(date).valueOf();
      if (currentDate < dates) {
        return true;
      }
      return false;
    };

    // 是否显示添加现货按钮
    const isShowAddPreStockOut = computed(() => {
      let flag = false;
      if (
        formData.value.shipment_info.status ===
          String(ShipmentStatus.Created) &&
        formData.value.shipment_info.invoice_status === 10 &&
        !loading.value
      ) {
        flag = true;
      }
      return flag;
    });

    const isDoubleSale = computed(() => {
      return formData.value.order_type_ds === 1;
    });

    const backToList = () => {
      commonBackToList(router, "/transport-management/shipments");
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      getFromInfo();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
      commonChangeDefaultDate,
      getShipmentStatusMap,
      ShipmentStatus,
      loading,
      options,
      formData,
      formRef,
      rules,
      shipmentItemId,
      parentId,
      itemTable,
      submitButton,
      upload_file_id,
      typeSameRule,
      serviceProviderChange,
      isDisabledTrackingNo,
      handleUseStockOut,
      handleEditUseStockOut,
      getShowInfo,
      getLocation,
      getFromInfo,
      handleReceivedShipment,
      submit,
      backToList,
      getProductIdData,
      getOfficialPrices,
      invoiceButtonGroupVisibleController,
      createProformaInvoice,
      previewProformaInvoice,
      createAgentDocuments,
      createDocuments,
      previewDoubleSaleCommercialInvoice,
      previewDeliveryNote,
      printPackingList,
      downloadPackingList,
      printCheckList,
      printStockOutList,
      uploadHeaders,
      id,
      uploadUrl,
      uploadDisabled,
      handleProgress,
      handleSuccess,
      handleError,
      handleExceed,
      handleRemove,
      handleDeleteUpload,
      handleBeforeUpload,
      handlePreview,
      setDisabledDate,
      isShowAddPreStockOut,
      isDoubleSale,
    };
  },
});
