
import { checkPermissionFlag } from "@/directives/permission";
import { defineComponent } from "vue";

export default defineComponent({
  name: "permission-common",
  props: {
    role: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      checkPermissionFlag,
    };
  },
});
