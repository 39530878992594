
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { ApiShipments } from "@/core/api";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import md5 from "js-md5";
import mixin from "@/mixins";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import { formatDateTime } from "@/core/directive/function/common";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";

export default defineComponent({
  components: { PermissionCommon },
  name: "use-stock-out",
  props: {
    id: { type: Number, default: 0 },
    parentId: { type: Number, default: 0 },
    status: { type: String, default: "" },
    invoice_status: { type: Number, default: 10 },
    shipment_type: { type: String, default: "" },
    shipment_invoice_id: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const useStockOut = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);

    const formData = ref({
      items: [],
    });

    const options = ref({
      merchant_profile_channel: [],
      merchant: [],
      defaultData: "",
    });

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const rules = ref({
      "items.unp_for_export_declaration": [
        {
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (value.toString().trim() === "") {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      "items.unp_for_import_declaration": [
        {
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (value.toString().trim() === "") {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      "items.quantity": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    watch(
      () => props.id,
      (newVal) => {
        if (newVal) {
          getFormData(newVal);
        } else {
          resetForm();
        }
      }
    );

    const isChangeData = computed(() => {
      const newData = md5(JSON.stringify(formData.value.items));
      const flag = newData !== options.value.defaultData;
      return flag && props.shipment_invoice_id !== 0;
    });

    const typeSameRule = computed(() => {
      const shipment_type = props.shipment_type;
      return shipment_type == "2" || shipment_type == "3";
    });

    const showItemChangeTip = (fn) => {
      Swal.fire({
        text: t("shipments.itemChangeTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          fn();
        }
      });
    };

    const isChangeFn = (fn) => {
      if (isChangeData.value) {
        showItemChangeTip(() => {
          fn();
        });
      } else {
        fn();
      }
    };

    const getFormData = async (id) => {
      loading.value = true;
      const { data } = await ApiShipments.getShipmentItemDetail({
        id: id,
        shipment_id: props.parentId,
      });
      loading.value = false;
      if (data.code == 0) {
        data.data.forEach((item) => {
          if (item.unp_for_export_declaration == 0) {
            item.unp_for_export_declaration = item.base_unit_sales_order_price;
          }
          item.max_quantity = item.quantity;
        });
        formData.value.items = data.data;
        // element plus在渲染时做了操作导致数据验证失败，需要延迟生成
        setTimeout(() => {
          options.value.defaultData = md5(JSON.stringify(data.data));
        }, 0);
      } else {
        showServerErrorMsg(data);
      }
    };

    const handleCurrentChange = (e, row) => {
      row.unused = e ? 0 : 1;
    };

    const removeItem = (item, index) => {
      Swal.fire({
        text: t("shipments.itemRemoveTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (props.shipment_invoice_id !== 0) {
            showItemChangeTip(() => {
              deleteShipmentItem(item.id);
            });
          } else {
            deleteShipmentItem(item.id);
          }
        }
      });
    };

    const deleteShipmentItem = async (id) => {
      loading.value = true;
      const { data } = await ApiShipments.deleteShipmentItem({
        id: [id],
        shipment_id: props.parentId,
      });
      loading.value = false;
      if (data.code == 0) {
        if (props.id) {
          emit("update-list");
          if (formData.value.items.length === 1) {
            handleDiscard();
          } else {
            getFormData(props.id);
          }
        }
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          isChangeFn(() => {
            updateShipmentItemDetail();
          });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const updateShipmentItemDetail = () => {
      loading.value = true;
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      ApiShipments.updateShipmentItemDetail(formData.value.items)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              resetForm();
              hideModal(useStockOut.value);
              emit("update-list");
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleDiscard = () => {
      hideModal(useStockOut.value);
    };

    const resetForm = () => {
      // formRef.value?.resetFields();
      formData.value.items = [];
    };

    onMounted(() => {
      modalHideListener(useStockOut.value, () => {
        resetForm();
        emit("reset-form");
      });
    });

    return {
      t,
      formatDateTime,
      props,
      loading,
      formData,
      useStockOut,
      submitButton,
      formRef,
      options,
      rules,
      isChangeData,
      typeSameRule,
      submit,
      resetForm,
      handleDiscard,
      handleCurrentChange,
      removeItem,
    };
  },
});
